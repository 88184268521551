

import { Vue, Component } from 'vue-property-decorator'
import { mapKey } from '@/utils/config'
import AMapLoader from '@amap/amap-jsapi-loader'
import LocationImg from '@/assets/img/icon_address.png'

@Component
export default class Detail extends Vue {
  private formData = {
    longitude: '',
    latitude: ''
  }

  private map: any = null
  private marker: Array<any> = []

  created () {
    this.loadData()
  }

  get deviceNumber () {
    return this.$route.query.deviceNumber as string
  }

  loadData () {
    this.$axios.get(this.$apis.ecologyMonitor.selectSoilWarningByPage, {
      deviceNumber: this.deviceNumber
    }).then(res => {
      this.formData = res.list[0]
      if (this.formData.longitude && this.formData.latitude) {
        this.initMap(Number(this.formData.longitude), Number(this.formData.latitude))
      } else {
        this.$message.warning('暂无设备位置')
      }
    })
  }

  // 初始化地图
  initMap (lng: number, lat: number) {
    AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: [
        'AMap.MouseTool'
      ]
    }).then((AMap) => {
      this.map = new AMap.Map('mapContainer', {
        center: [lng, lat],
        zoom: 15
      })
      this.addMarker(lng, lat)
    })
  }

  addMarker (lng: number, lat: number) {
    if (lng && lat) {
      this.marker = []
      const marker: any = new AMap.Marker({
        map: this.map,
        position: new AMap.LngLat(lng, lat),
        offset: new AMap.Pixel(-20, -10),
        icon: LocationImg
      })
      this.marker.push(marker)
      this.map.add(marker)
    }
  }
}
